import {GetResp} from '@em/shared/api-interface/lib/types/view-models/external-campaigns/get';
import {IPlatform} from '@em/shared/platforms/util';
import {ICampaignFilterSet} from '@em/data-feed/data-access-products';
import {CountryCode, IMoney} from '@em/shared/util-types';
import {CurrencyCode} from '@em/shared/util-types';
import {facebookCampaignType} from './types';
import {
  CampaignPrimaryStatus,
  CampaignPrimaryStatusReasons,
  CampaignServingStatus,
} from '@em/campaigns/domain-models';

export interface ExternalCampaignModel {
  readonly platform: IPlatform;
  readonly platformAttributes: CampaignPlatformAttributesModel;
  readonly automaticCampaignAttributes: AutomaticCampaignAttributesModel;
  readonly name: string | undefined;
  readonly isActive: boolean;
  readonly isPlatformDeleted: boolean;
  canBeEdited: boolean;
}

export type PriorityCode = Exclude<
  GetResp[0]['platform_attributes']['priority'],
  undefined
>;

export interface ICampaignPerformanceDataModel {
  readonly conversions: number | null;
  readonly products: number | null;
  readonly clicks: number | null;
  readonly revenue: IMoney | null;
  readonly adSpend: IMoney | null;
  readonly roas: number | null;
}

export const nullPerformanceData: ICampaignPerformanceDataModel = {
  conversions: null,
  products: null,
  clicks: null,
  revenue: null,
  adSpend: null,
  roas: null,
};

export interface ManagedCampaignModel extends ExternalCampaignModel {
  readonly filterSet?: ICampaignFilterSet;
  readonly uuid?: string;
  readonly state: 'active' | 'paused' | 'deleted';
  readonly isNew: boolean;
}

export function createExternalCampaign(
  platform: IPlatform,
  platformAttributes: CampaignPlatformAttributesModel = {},
  automaticCampaignAttributes: AutomaticCampaignAttributesModel = {
    active: true,
    invertProducts: false,
  },
): ExternalCampaignModel {
  return {
    platform,
    platformAttributes,
    automaticCampaignAttributes,
    name: platformAttributes ? platformAttributes.name : undefined,
    isActive: !!platformAttributes.active,
    isPlatformDeleted: !platformAttributes.id,
    canBeEdited: false,
  };
}

export function createManagedCampaign(
  platform: IPlatform,
  filterSet?: ICampaignFilterSet,
  uuid?: string,
  platformAttributes: CampaignPlatformAttributesModel = {},
  automaticCampaignAttributes: AutomaticCampaignAttributesModel = {
    active: true,
    invertProducts: false,
  },
): ManagedCampaignModel {
  return {
    platform,
    filterSet,
    uuid,
    platformAttributes,
    automaticCampaignAttributes,
    isActive: !!platformAttributes.active,
    isNew: !uuid,
    isPlatformDeleted: !platformAttributes.id,
    name: platformAttributes ? platformAttributes.name : undefined,
    state: getCampaignState(
      !platformAttributes.id,
      !!platformAttributes.active,
    ),
    canBeEdited: true,
  };
}

export function createNewManagedCampaignModel(
  platform: IPlatform,
  countryCode?: CountryCode,
  currencyCode?: CurrencyCode,
): ManagedCampaignModel {
  const budget = currencyCode ? {value: 0, currencyCode} : undefined;
  return {
    platform,
    filterSet: undefined,
    uuid: undefined,
    platformAttributes: {
      active: true,
      priority: 0,
      budget,
      countryCode,
    },
    automaticCampaignAttributes: {active: true, invertProducts: false},
    name: undefined,
    isActive: true,
    isPlatformDeleted: true,
    isNew: true,
    state: getCampaignState(true, true),
    canBeEdited: true,
  };
}

export function getCampaignState(
  isPlatformDeleted: boolean,
  isActive: boolean,
): 'active' | 'paused' | 'deleted' {
  if (isPlatformDeleted) {
    return 'deleted';
  } else if (isActive) {
    return 'active';
  } else {
    return 'paused';
  }
}

export interface CampaignPlatformAttributesModel {
  readonly id?: string;
  name?: string;
  active?: boolean;
  biddingStrategyType?: string;
  type?: facebookCampaignType | null;
  priority?: PriorityCode;
  budget?: IMoney | null;
  countryCode?: CountryCode;
  pageId?: string;
  pixelId?: string;
  targetRoas?: number;
  servingStatus?: CampaignServingStatus;
  primaryStatus?: CampaignPrimaryStatus;
  primaryStatusReasons?: CampaignPrimaryStatusReasons;
}

export interface AutomaticCampaignAttributesModel {
  active: boolean;
  invertProducts?: boolean;
  selectedFilterSetUuid?: string | null;
}

import {IPlatform} from '../types/platform';

export const GOOGLE: Readonly<IPlatform> = {
  id: 'google-shopping',
  name: 'Google',
  i18nKey: 'GOOGLE',
  colors: {
    charts: '#FF7900',
  },
  logos: {
    general: 'assets/images/google/google-logo.svg',
    mono: 'assets/images/google/google_mono.svg',
    campaign: 'assets/images/google/google_logo_small.svg',
    merchantCenter: 'assets/images/google/google-merchant-center.svg',
    googlePrices: 'assets/images/google/google-prices.png',
  },
  supportsPriority: true,
  supportsDailyBudget: true,
  supportsKpis: false,
  supportsPerformanceTimeline: false,
};
